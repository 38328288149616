import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { Flex, TabView, theme } from "@xometry/xometry_loft";
import {
  GetUsersOrganizationsByIdentityQuery,
  GetUsersOrganizationsByIdentityQueryResult,
} from "../../schema/generated/schema";
import { origins } from "../../utils/constants";
import { genericTrack } from "../../utils/analytics";

const PERSONAL_DASHBOARD_VALUE = "personal-dashboard";
const CREATE_NEW_TEAM = "create-team";

interface Props {
  loggedInUserId: string;
  setUsersOrgs: (
    orgs: GetUsersOrganizationsByIdentityQueryResult["getUsersOrganizationsByIdentity"]
  ) => void;
}
const OrganizationSwitcher = ({ loggedInUserId, setUsersOrgs }: Props) => {
  const {
    data: { getUsersOrganizationsByIdentity: orgs = [] } = {},
  } = useQuery<GetUsersOrganizationsByIdentityQueryResult>(
    GetUsersOrganizationsByIdentityQuery,
    {
      variables: {
        application: "xometry",
        userId: loggedInUserId,
      },
    }
  );

  useEffect(() => {
    if (orgs.length) {
      setUsersOrgs([...orgs]);
    }
  }, [orgs]);

  // refId === teamId
  const selectChangeHandler = (value: string) => {
    if (value === CREATE_NEW_TEAM) {
      genericTrack("Teamspace Dashboard Tab Clicked", {
        userId: loggedInUserId,
        action: "Create New Team",
        teamOrgId: null,
        currentPageUrl: window.location.href,
      });
      window.location.assign(`${origins.home}/account/organization/create`);
    } else if (value !== PERSONAL_DASHBOARD_VALUE) {
      const teamId = encodeURIComponent(value);
      genericTrack("Teamspace Dashboard Tab Clicked", {
        userId: loggedInUserId,
        action: "navigate",
        teamOrgId: teamId,
        currentPageUrl: window.location.href,
      });
      window.location.assign(`${origins.home}/quoting/org/${teamId}`);
    }
  };

  const switcherButton = (option: OptionConfig) => {
    return (
      <TabView.Header.Tab
        paddingX={20}
        width="fit-content"
        onClick={() => selectChangeHandler(option.refId)}
      >
        {option.name}
      </TabView.Header.Tab>
    );
  };
  const switcherOptions = [
    {
      name: "Personal",
      refId: PERSONAL_DASHBOARD_VALUE,
    },
    ...orgs,
    {
      name: (
        <>
          <i className="fas fa-plus" />
          {` Create a New Team`}
        </>
      ),
      refId: CREATE_NEW_TEAM,
    },
  ];
  const switcherButtonList = switcherOptions.map(option =>
    switcherButton(option)
  );

  return (
    <Flex backgroundColor={theme.colors.white}>
      <Flex width="100%">
        <TabView marginBottom="-2px">
          <TabView.Header>{switcherButtonList}</TabView.Header>
        </TabView>
      </Flex>
    </Flex>
  );
};

export default OrganizationSwitcher;
